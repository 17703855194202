<script>
    import { getContext } from 'svelte';
    const postBidContext = getContext('postBidContext');
    import Pusher from 'pusher-js';
    import toast from 'svelte-french-toast';

    const {
        filteredPostForPostBid,
        authUser,
        pusherKey,
        pusherCluster,
        socketsDriver,
        soketiHost,
        soketiPort,
        pusherScheme,
    } = postBidContext;
    import axios from 'axios';

    function getCurrentTimestamp() {
        return new Date().toISOString().slice(0, 19).replace('T', ' ');
    }

    function initTheTimer(bidValidity, id) {
        const currentTime = getCurrentTimestamp();
        initTimer(bidValidity, currentTime, id);
        return '';
    }

    function minimumPriceCalculator(inputPrice) {
        if (inputPrice < 5) {
            const number = 0.2 + inputPrice;
            return parseFloat(number.toFixed(2));
        }
        if (inputPrice > 5 && inputPrice < 10) {
            const number = 0.5 + inputPrice;
            return parseFloat(number.toFixed(2));
        }
        const number = inputPrice / 10;
        const formattedNumber = parseFloat(number.toFixed(2));
        const totalOutput = formattedNumber + inputPrice;
        return totalOutput.toFixed(2);
    }

    let params = {
        cluster: pusherCluster,
    };
    if (socketsDriver === 'soketi') {
        params = {
            wsHost: soketiHost,
            wsPort: soketiPort,
            forceTLS: pusherScheme ? true : false,
        };
    }

    var pusher = new Pusher(pusherKey, params);

    function placePostBid(auctionId, postId) {
        document.querySelector(`#spinner_${auctionId}`).style.display = 'inline-block';

        const placeBidButton = document.querySelector(`#button_${auctionId}`);
        placeBidButton.querySelector('.buttonText').style.display = 'none';

        let currentPrice = document.querySelector(`#current_price_${auctionId}`).value;
        let currentBidderUserId = document.querySelector(`#bidder_user_id_${auctionId}`).value;

        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['X-CSRF-TOKEN'] = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content');

        const dataToSend = {
            bidder_user_id: currentBidderUserId,
            current_price: currentPrice,
            post_id: postId,
        };

        axios
            .post('/post-bid/place-bid', dataToSend, {
                headers: {
                    'X-Socket-Id': pusher.connection.socket_id,
                },
            })
            .then((response) => {
                const res = response.data;
                // Hide the spinner and enable the button
                placeBidButton.disabled = false;
                placeBidButton.querySelector('.spinner').style.display = 'none';
                placeBidButton.querySelector('.buttonText').style.display = 'inline-block';

                initTimer(res.validity, res.nowTime, res.auction_id);
                document.querySelector(`#current_bidder_name_${res.auction_id}`).textContent = res.current_bidder.name;
                document.querySelector(`#current_bid_price_${res.auction_id}`).textContent = res.current_bid_price;
                document.querySelector(`#min_price_${res.auction_id}`).textContent = res.next_minimum_price;
                document.querySelector(`#current_price_${res.auction_id}`).value = res.next_minimum_price;

                toast.success(res.message);
            })
            .catch((error) => {
                placeBidButton.disabled = false;
                placeBidButton.querySelector('.spinner').style.display = 'none';
                placeBidButton.querySelector('.buttonText').style.display = 'inline-block';

                if (error.response && error.response.status === 422) {
                    const errors = error.response.data.error;
                    toast.error(errors);
                } else {
                    toast.error(error.message);
                }
            });
    }
</script>

{#if filteredPostForPostBid.length > 0}
    {#each filteredPostForPostBid as auction}
        <div class="m-3" id="{'post_bid_' + auction.post.id}">
            bid for : <span style="color: #b6078d">
                <a target="blank" href="#"> {auction.post.text} </a>
            </span> <br />
            <h4>post info</h4>
            Attachments : {auction.post.attachments_count} <br />
            Reacttions : {auction.post.reactions_count} <br />
            comments : {auction.post.comments_count} <br />
            bookmark : {auction.post.bookmarks_count} <br />
            sold count : {auction.post.purchase_count} <br />
            <hr />

            {#if auction.latest_bid}
                Current Bidder : <b id="{'current_bidder_name_' + auction.id}">
                    {auction.latest_bid?.bidder.name}
                </b> <br />
                Current Bid price :
                <span>
                    <b id="{'current_bid_price_' + auction.id}">
                        {auction.latest_bid.amount}
                    </b></span
                > <br />
                <div>
                    Minimum Price For bid: <b>
                        <span id="{'min_price_' + auction.id}">
                            {minimumPriceCalculator(auction.latest_bid.amount)}
                        </span>
                    </b>
                </div>
                <br />

                {initTheTimer(auction.latest_bid.bid_validity, auction.id)}
            {:else}
                Current Bidder : <b id="{'current_bidder_name_' + auction.id}"> </b>
                <br />
                Current Bid price :
                <span> <b id="{'current_bid_price_' + auction.id}"> 0</b></span>
                <div>
                    Minimum Price For bid: <b>
                        <span id="{'min_price_' + auction.id}"> 0 </span>
                    </b>
                </div>
                <br />
            {/if}

            <div>time left: <span id="{'timer_' + auction.id}"> </span></div>

            <form method="post" id="{'form_' + auction.id}">
                <input
                    type="number"
                    step="0.1"
                    id="{'bidder_user_id_' + auction.id}"
                    name="bidder_user_id"
                    value="{authUser}"
                    class="form-control"
                    hidden
                /> <br />

                {#if auction.latest_bid}
                    <label class="form-label" for="current_price">Enter your Bid Price</label>
                    <input
                        type="text"
                        name="current_price"
                        id="{'current_price_' + auction.id}"
                        value="{minimumPriceCalculator(auction.latest_bid.amount)}"
                        min="{auction.latest_bid.amount}"
                        class="form-control"
                    /> <br />
                {:else}
                    <label class="form-label" for="current_price">Enter your Bid Price</label>
                    <input
                        type="text"
                        id="{'current_price_' + auction.id}"
                        name="current_price"
                        value="{auction.start_price}"
                        class="form-control"
                    /> <br />
                {/if}

                <button
                    type="button"
                    id="{'button_' + auction.id}"
                    on:click="{() => placePostBid(auction.id, auction.post.id)}"
                    class="btn btn-sm btn-success"
                >
                    <span id="{'spinner_' + auction.id}" class="spinner" style="display: none">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M15 4v7h5V4h-5zM4 15h5v5H4v-5zM4 4v5h5V4H4zm11 11h5v5h-5v-5z" fill="currentColor"
                            ></path>
                            <path d="M8 8v8h8V8H8zm7 7H9V9h6v6z" fill="currentColor"></path>
                        </svg>
                    </span>
                    <span class="buttonText">Place Bid</span>
                </button>
            </form>
        </div>
    {/each}
{:else}
    <div class="m-5 text-center">
        <h4>No post available for bid</h4>
    </div>
{/if}
